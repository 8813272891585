import { NavLink } from "react-router-dom";

import Logo from "../../assets/logos/SM_Logo_White.svg";
import InstaIcon from "../../assets/icons/socials/IG_ORANGE.svg";
import InstaGlow from "../../assets/icons/socials/IG_GLOW.svg";
import FbIcon from "../../assets/icons/socials/FB_ORANGE.svg";
import FbGlow from "../../assets/icons/socials/FB_GLOW.svg";
import TiktokIcon from "../../assets/icons/socials/TT_ORANGE.svg";
import TtGlow from "../../assets/icons/socials/TT_GLOW.svg";
import YoutubeIcon from "../../assets/icons/socials/YT_ORANGE.svg";
import YtGlow from "../../assets/icons/socials/YT_GLOW.svg";
import LILogo from "../../assets/icons/socials/LinkedIn.png";

import "./Footer.scss";

export default function Footer() {
  return (
    <div className="footer">
      <div className="footer__container">
        <NavLink
          className="header__logo-link"
          to="/"
        >
          <img
            className="footer__logo"
            src={Logo}
            alt="Logo"
          />
        </NavLink>
        <div className="footer__socials">
          <a
            className="hero__socials-link"
            href="https://www.instagram.com/localroutes.co/"
          >
            <img
              className="hero__socials-icon"
              src={InstaIcon}
              alt="insta icon"
            />
            <img
              className="hero__socials-icon hero__socials-icon--hover"
              src={InstaGlow}
              alt="Instagram Icon Glow"
            />
          </a>
          <a
            className="hero__socials-link"
            href="https://www.instagram.com/localroutes.co/"
          >
            <img
              className="hero__socials-icon"
              src={FbIcon}
              alt="facebook icon"
            />
            <img
              className="hero__socials-icon hero__socials-icon--hover"
              src={FbGlow}
              alt="Facebook Icon Glow"
            />
          </a>
          <a
            className="hero__socials-link"
            href="https://www.instagram.com/localroutes.co/"
          >
            <img
              className="hero__socials-icon"
              src={TiktokIcon}
              alt="tiktok icon"
            />
            <img
              className="hero__socials-icon hero__socials-icon--hover"
              src={TtGlow}
              alt="TikTok Icon Glow"
            />
          </a>
          <a
            className="hero__socials-link"
            href="https://www.instagram.com/localroutes.co/"
          >
            <img
              className="hero__socials-icon"
              src={YoutubeIcon}
              alt="youtube icon"
            />
            <img
              className="hero__socials-icon hero__socials-icon--hover"
              src={YtGlow}
              alt="YouTube Icon Glow"
            />
          </a>
        </div>
      </div>
      <div className="footer__contacts">
        <div className="footer__contact">
          <div className="footer__contact--flex">
            <a
              className="footer__contact-link"
              href="https://www.linkedin.com/in/nfeizarbabi/"
            >
              <img
                className="footer__contact-icon"
                src={LILogo}
                alt="linkedin icon"
              />
            </a>
            <h5>Negin Feiz Arbabi</h5>
          </div>
          <p>Director of Operations</p>
        </div>
        <div className="footer__contact">
          <div className="footer__contact--flex">
            <a
              className="footer__contact-link"
              href="https://www.linkedin.com/in/milena-yared-86b447239/"
            >
              <img
                className="footer__contact-icon"
                src={LILogo}
                alt="linkedin icon"
              />
            </a>
            <h5>Milena Yared</h5>
          </div>
          <p>Creative Director</p>
        </div>
        <div className="footer__contact">
          <div className="footer__contact--flex">
            <a
              className="footer__contact-link"
              href="https://www.linkedin.com/in/janvieremukama/"
            >
              <img
                className="footer__contact-icon"
                src={LILogo}
                alt="linkedin icon"
              />
            </a>
            <h5>Janvière Mukama</h5>
          </div>
          <p>Marketing Director</p>
        </div>
      </div>
      <p className="footer__copyright">
        © 2024 Local Routes Travel & Hospitality. All rights reserved.
      </p>
    </div>
  );
}
