import { useState, useEffect, forwardRef } from "react";
import axios from "axios";

import LogoWhite from "../../assets/logos/SM_Logo_White.svg";

import "./ContactForm.scss";

const ContactForm = forwardRef((props, ref) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    message: "",
  });
  const [status, setStatus] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    let timer;
    if (status === "success") {
      timer = setTimeout(() => {
        setStatus(null);
      }, 3000);
    }
    return () => clearTimeout(timer);
  }, [status]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await axios.post("http://localhost:8080/contact", formData);
      setStatus("success");
      setErrorMessage("");
      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        message: "",
      });
    } catch (error) {
      setStatus("error");
      setErrorMessage(
        error.response?.data?.error ||
          "Contact form submission failed. Please try again."
      );
    }
  };

  return (
    <div
      className="contact"
      ref={ref}
    >
      <div className="contact__container">
        <div className="contact__card">
          <div className="contact__header">
            <img
              className="contact__header-logo"
              src={LogoWhite}
              alt="White logo"
            />
            <h3 className="contact__header-title">Contact Us</h3>
          </div>
          <form
            className="contact__form"
            onSubmit={handleSubmit}
          >
            <div className="contact__form-fields--flex">
              <div className="contact__form-fields">
                <label className="contact__form-label">First Name</label>
                <input
                  className="contact__form-input"
                  type="text"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="contact__form-fields">
                <label className="contact__form-label">Last Name</label>
                <input
                  className="contact__form-input"
                  type="text"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="contact__form-fields">
              <label className="contact__form-label">Email Address</label>
              <input
                className="contact__form-input"
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="contact__form-fields">
              <label className="contact__form-label">Message</label>
              <textarea
                className="contact__form-input"
                name="message"
                rows="4"
                value={formData.message}
                onChange={handleChange}
                required
              />
            </div>
            {status === "success" && (
              <p className="contact__form--success">
                Thank you for contacting us!
              </p>
            )}
            {status === "error" && (
              <p className="contact__form--error">{errorMessage}</p>
            )}
            <button
              className="contact__form-button"
              type="submit"
            >
              Send Message
            </button>
          </form>
        </div>
      </div>
    </div>
  );
});

export default ContactForm;
