import { useState, forwardRef } from "react";

import Popup from "../MerchPopup/MerchPopup";
import ComingSoon from "../../assets/images/ComingSoon.svg";

import "./Merch.scss";

function Merch(_, ref) {
  const [isPopupVisible, setPopupVisible] = useState(false);

  const togglePopup = () => {
    setPopupVisible(!isPopupVisible);
  };

  return (
    <div
      ref={ref}
      className="merch"
    >
      <div className="merch__header-container">
        <h4 className="merch__header">Coming Soon!</h4>
        <p className="merch__subheader">
          Discover our collection of Local Routes travel gear to elevate
          your adventures!
        </p>
        <button
          className="merch__button"
          type="button"
          onClick={togglePopup}
        >
          Preorder Here
        </button>
      </div>
      <img
        className="merch__photo"
        src={ComingSoon}
        alt="Towel, stickers, hat merch"
      />
      {isPopupVisible && <Popup onClose={togglePopup} />}
    </div>
  );
}

export default forwardRef(Merch);
