import { useEffect, useState, useCallback } from "react";

export const scrollToSection = (elementRef) => {
  const header = document.querySelector(".header");
  const headerHeight = header ? header.offsetHeight : 0;

  if (elementRef.current) {
    const offsetPosition = elementRef.current.offsetTop - headerHeight;
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  } else {
    console.error("ElementRef current is null");
  }
};

export const useScroll = (scrollThreshold) => {
  const [showButton, setShowButton] = useState(false);

  const onScroll = useCallback(() => {
    window.scrollY > scrollThreshold
      ? setShowButton(true)
      : setShowButton(false);
  }, [scrollThreshold]);

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [onScroll]);

  return showButton;
};

export const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });
};
