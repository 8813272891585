import { useState, useEffect, forwardRef } from "react";
import validator from "validator";
import SubscriptionAPI from "../../classes/SubscriptionAPI";

import LocalRoutesLogo from "../../assets/logos/Main_Logo_White.png";
import InstaIcon from "../../assets/icons/socials/IG_ORANGE.svg";
import InstaGlow from "../../assets/icons/socials/IG_GLOW.svg";
import FbIcon from "../../assets/icons/socials/FB_ORANGE.svg";
import FbGlow from "../../assets/icons/socials/FB_GLOW.svg";
import TiktokIcon from "../../assets/icons/socials/TT_ORANGE.svg";
import TtGlow from "../../assets/icons/socials/TT_GLOW.svg";
import YoutubeIcon from "../../assets/icons/socials/YT_ORANGE.svg";
import YtGlow from "../../assets/icons/socials/YT_GLOW.svg";

import "./Hero.scss";

const Hero = forwardRef((props, ref) => {
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    let timer;
    if (status === "success" || status === "error") {
      timer = setTimeout(() => {
        setStatus(null);
      }, 3000);
    }
    return () => clearTimeout(timer);
  }, [status]);

  const handleInputChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validator.isEmail(email)) {
      setStatus("error");
      setErrorMessage(
        "Invalid email address. Please enter a valid email."
      );
      return;
    }

    try {
      await SubscriptionAPI.subscribe(email);
      setStatus("success");
      setErrorMessage("");
    } catch (error) {
      setStatus("error");
      setErrorMessage(
        error.response?.data?.error ||
          "Subscription failed. Please try again."
      );
    }

    setEmail("");
  };

  return (
    <div
      ref={ref}
      className="hero"
    >
      <div className="hero__card">
        <img
          className="hero__logo"
          src={LocalRoutesLogo}
          alt="Local Routes Logo"
        />
        <h1 className="hero__slogan">Get local with us.</h1>
        <h2 className="hero__subheader">
          Connect with communities, not destinations.
        </h2>
        <form
          className="hero__subscribe-form"
          onSubmit={handleSubmit}
        >
          <div className="hero__subscribe-container">
            <input
              className="hero__subscribe-input"
              type="email"
              placeholder="Email"
              required
              value={email}
              name="EMAIL"
              autoComplete="email"
              onChange={handleInputChange}
            />
            <button
              className="hero__subscribe-button"
              type="submit"
            >
              SUBSCRIBE
            </button>
          </div>
        </form>
        {status === "success" && (
          <p className="hero__subscribe-input--success">
            Thank you for subscribing!
          </p>
        )}
        {status === "error" && (
          <p className="hero__subscribe-input--error">{errorMessage}</p>
        )}
        <div className="hero__socials">
          <a
            className="hero__socials-link"
            href="https://www.instagram.com/localroutes.co/"
          >
            <img
              className="hero__socials-icon"
              src={InstaIcon}
              alt="insta icon"
            />
            <img
              className="hero__socials-icon hero__socials-icon--hover"
              src={InstaGlow}
              alt="Instagram Icon Glow"
            />
          </a>
          <a
            className="hero__socials-link"
            href="https://www.instagram.com/localroutes.co/"
          >
            <img
              className="hero__socials-icon"
              src={FbIcon}
              alt="facebook icon"
            />
            <img
              className="hero__socials-icon hero__socials-icon--hover"
              src={FbGlow}
              alt="Facebook Icon Glow"
            />
          </a>
          <a
            className="hero__socials-link"
            href="https://www.instagram.com/localroutes.co/"
          >
            <img
              className="hero__socials-icon"
              src={TiktokIcon}
              alt="tiktok icon"
            />
            <img
              className="hero__socials-icon hero__socials-icon--hover"
              src={TtGlow}
              alt="TikTok Icon Glow"
            />
          </a>
          <a
            className="hero__socials-link"
            href="https://www.instagram.com/localroutes.co/"
          >
            <img
              className="hero__socials-icon"
              src={YoutubeIcon}
              alt="youtube icon"
            />
            <img
              className="hero__socials-icon hero__socials-icon--hover"
              src={YtGlow}
              alt="YouTube Icon Glow"
            />
          </a>
        </div>
      </div>
    </div>
  );
});

export default Hero;
