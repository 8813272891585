import { NavLink } from "react-router-dom";

import { scrollToSection } from "../../utils/scrollUtils";
import LocalRoutesLogo from "../../assets/logos/Simplifed_Logo_White.png";

import "./Header.scss";

export default function Header({ homeRef, merchRef, contactRef }) {
  return (
    <header className="header">
      <div className="header__left-nav">
        <NavLink
          className="header__logo-link"
          to="/"
        >
          <img
            className="header__logo"
            src={LocalRoutesLogo}
            alt="Local Routes Logo"
          />
        </NavLink>
      </div>
      <div className="header__right-nav">
        <ul className="header__navbar">
          <li>
            <button
              className="header__button"
              onClick={() => scrollToSection(homeRef)}
            >
              Home
            </button>
          </li>
          <li>
            <button
              className="header__button"
              onClick={() => scrollToSection(contactRef)}
            >
              Contact
            </button>
          </li>
          <li>
            <button
              className="header__button"
              onClick={() => scrollToSection(merchRef)}
            >
              Shop
            </button>
          </li>
        </ul>
      </div>
    </header>
  );
}
