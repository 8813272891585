import { useRef } from "react";
import { FaArrowUp } from "react-icons/fa";
import { useScroll, scrollToTop } from "../../utils/scrollUtils";

import Header from "../../components/Header/Header";
import Hero from "../../components/Hero/Hero";
import HeroBanner from "../../components/HeroBanner/HeroBanner";
import ContactForm from "../../components/ContactForm/ContactForm";
import Merch from "../../components/Merch/Merch";
import Footer from "../../components/Footer/Footer";

import "./Home.scss";

export default function Home() {
  const homeRef = useRef(null);
  const merchRef = useRef(null);
  const contactRef = useRef(null);
  const showButton = useScroll(25);

  return (
    <>
      <Header
        homeRef={homeRef}
        merchRef={merchRef}
        contactRef={contactRef}
      />
      <div className="home">
        <Hero ref={homeRef} />
        <HeroBanner
          merchRef={merchRef}
          contactRef={contactRef}
        />
        <section id="contact">
          <ContactForm ref={contactRef} />
        </section>
        <section id="merch">
          <Merch ref={merchRef} />
        </section>
        <Footer />
      </div>
      <FaArrowUp
        className={
          showButton ? "scroll-button--visible" : "scroll-button--hidden"
        }
        onClick={scrollToTop}
      />
    </>
  );
}
