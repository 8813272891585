import axios from "axios";

const axiosInstance = axios.create({
  baseURL: "http://localhost:8080",
});

class InventoryAPI {
  static async getInventory() {
    try {
      const response = await axiosInstance.get("/inventory");
      return response.data;
    } catch (error) {
      throw (
        error.response?.data?.error ||
        "Failed to fetch inventory. Please try again."
      );
    }
  }
}

export default InventoryAPI;
