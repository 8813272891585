import axios from "axios";

const axiosInstance = axios.create({
  baseURL: "http://localhost:8080",
});

class PreorderAPI {
  static async createPreorder(preorderData) {
    try {
      const response = await axiosInstance.post("/preorder", preorderData);
      return response.data;
    } catch (error) {
      throw (
        error.response?.data?.error || "Preorder failed. Please try again."
      );
    }
  }
}

export default PreorderAPI;
