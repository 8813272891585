import { useState, useEffect } from "react";

import InventoryAPI from "../../classes/InventoryAPI";
import PreorderAPI from "../../classes/PreorderAPI";

import Alien from "../../assets/images/Alien.svg";

import "./MerchPopup.scss";

const Popup = ({ onClose }) => {
  const [items, setItems] = useState([{ type: "", quantity: "" }]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });
  const [error, setError] = useState(null);
  const [inventory, setInventory] = useState([]);

  useEffect(() => {
    const fetchInventory = async () => {
      try {
        const data = await InventoryAPI.getInventory();
        setInventory(data);
      } catch (error) {
        setError(error);
      }
    };
    fetchInventory();
  }, []);

  const addItem = () => {
    if (items.length < 3) {
      setItems([...items, { type: "", quantity: "" }]);
    }
  };

  const handleChange = (index, field, value) => {
    const newItems = [...items];
    newItems[index][field] = value;
    setItems(newItems);
  };

  const handleFormDataChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const clearItems = () => {
    setItems([{ type: "", quantity: "" }]);
  };

  const clearLastItem = () => {
    if (items.length > 1) {
      const newItems = items.slice(0, -1);
      setItems(newItems);
    } else {
      clearItems();
    }
  };

  const isFormValid = () => {
    return (
      formData.firstName &&
      formData.lastName &&
      formData.email &&
      items.every((item) => item.type && item.quantity)
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isFormValid()) {
      try {
        const preorderData = { ...formData, items };
        await PreorderAPI.createPreorder(preorderData);
        setIsSubmitted(true);
      } catch (error) {
        setError("Preorder failed. Please try again.");
      }
    }
  };

  return (
    <div className="popup">
      <div className="popup__container">
        <div
          className={`popup__card ${
            isSubmitted ? "popup__card--highlight" : ""
          }`}
        >
          <button
            className="popup__close"
            onClick={onClose}
          >
            X
          </button>
          {isSubmitted ? (
            <div className="popup__thank-you">
              <h4 className="popup__header">
                Thank you for your preorder!
              </h4>
              <img
                className="popup__image"
                src={Alien}
                alt="Alien wearing bucket hat with logo"
              />
              <p className="popup__subheader">
                We'll be in touch with details about your order.
              </p>
            </div>
          ) : (
            <>
              <div className="popup__inventory">
                {inventory.map((item) => (
                  <div
                    key={item.id}
                    className="popup__inventory-item"
                  >
                    {item.image && (
                      <img
                        src={`http://localhost:8080${item.image}`}
                        alt={item.name}
                        className="popup__inventory-image"
                      />
                    )}
                    <h5 className="popup__inventory-name">{item.name}</h5>
                    <p className="popup__inventory-description">
                      {item.description}
                    </p>
                  </div>
                ))}
              </div>
              <form
                className="popup__form"
                onSubmit={handleSubmit}
              >
                <div className="popup__flex-container">
                  <div className="popup__form-fields">
                    <label className="popup__form-label">First Name</label>
                    <input
                      className="popup__form-input"
                      type="text"
                      name="firstName"
                      value={formData.firstName}
                      onChange={handleFormDataChange}
                      required
                    />
                  </div>
                  <div className="popup__form-fields">
                    <label className="popup__form-label">Last Name</label>
                    <input
                      className="popup__form-input"
                      type="text"
                      name="lastName"
                      value={formData.lastName}
                      onChange={handleFormDataChange}
                      required
                    />
                  </div>
                </div>
                <div className="popup__form-fields">
                  <label className="popup__form-label">
                    Email Address
                  </label>
                  <input
                    className="popup__form-input"
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleFormDataChange}
                    required
                  />
                </div>
                {items.map((item, index) => (
                  <div
                    key={index}
                    className="popup__flex-container popup__flex-container--space-between"
                  >
                    <div className="popup__form-fields">
                      <label className="popup__form-label">Item</label>
                      <select
                        className="popup__form-input popup__form-input--dropdown"
                        name="item"
                        value={item.type}
                        onChange={(e) =>
                          handleChange(index, "type", e.target.value)
                        }
                        required
                      >
                        <option value="">Select an item</option>
                        {inventory.map((option) => {
                          const isSelected = items.some(
                            (selectedItem) =>
                              selectedItem.type === option.type
                          );
                          return (
                            <option
                              key={option.type}
                              value={option.type}
                              disabled={isSelected}
                            >
                              {option.type}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="popup__form-fields popup__form-fields--small">
                      <label className="popup__form-label">Quantity</label>
                      <select
                        className="popup__form-input popup__form-input--dropdown"
                        name="quantity"
                        value={item.quantity}
                        onChange={(e) =>
                          handleChange(index, "quantity", e.target.value)
                        }
                        required
                      >
                        <option value="">0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                      </select>
                    </div>
                  </div>
                ))}
                {items[0].type && items.length < 3 && (
                  <div
                    className="popup__add-item"
                    onClick={addItem}
                  >
                    + Add item
                  </div>
                )}
                {items[0].type && (
                  <>
                    <div
                      className="popup__clear-item"
                      onClick={clearLastItem}
                    >
                      - Clear last selection
                    </div>
                    <div
                      className="popup__clear-item"
                      onClick={clearItems}
                    >
                      - Clear all
                    </div>
                  </>
                )}
                {error && <p className="popup__error">{error}</p>}
                <button
                  className="popup__button"
                  type="submit"
                  disabled={!isFormValid()}
                >
                  Pre-order
                </button>
              </form>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Popup;
