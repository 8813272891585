import { scrollToSection } from "../../utils/scrollUtils";

import CommunityIcon from "../../assets/icons/services/Community.svg";
import CommunityIconGlow from "../../assets/icons/services/Community_Glow.svg";
import ConsultingIcon from "../../assets/icons/services/Consulting.svg";
import ConsultingIconGlow from "../../assets/icons/services/Consulting_Glow.svg";

import "./HeroBanner.scss";

export default function HeroBanner({ merchRef, contactRef }) {
  return (
    <div className="banner">
      <div className="banner__services">
        <div
          className="banner__service"
          onClick={() => scrollToSection(merchRef)}
        >
          <img
            className="banner__service-icon"
            src={CommunityIcon}
            alt="Community icon"
          />
          <img
            className="banner__service-icon banner__service-icon--hover"
            src={CommunityIconGlow}
            alt="Community icon glow"
          />
        </div>
        <div
          className="banner__service"
          onClick={() => scrollToSection(contactRef)}
        >
          <img
            className="banner__service-icon"
            src={ConsultingIcon}
            alt="Consulting icon"
          />
          <img
            className="banner__service-icon banner__service-icon--hover"
            src={ConsultingIconGlow}
            alt="Consulting icon glow"
          />
        </div>
      </div>
      <p className="banner__description">
        Explore authentic adventures & cultural exchange with{" "}
        <span className="banner__description--highlight">
          Local Routes! {""}
        </span>
        Travelers, discover insider tips & inspiring stories. Businesses,
        reach engaged travelers & curate experiences. Sport our gear and
        connect with our vibrant community.
      </p>
      <h2 className="banner__description--highlight banner__description--cta">
        {" "}
        Join Us!
      </h2>
    </div>
  );
}
