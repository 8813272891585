import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

import "./NotFound.scss";

export default function NotFoundPage() {
  return (
    <>
      <Header />
      <div className="error-page">
        <h1 className="error-page__header">404 - Page Not Found</h1>
        <p className="error-page__description">
          The page you are looking for does not exist.
        </p>
        <div className="error-page__animation">
          <div
            data-js="astro"
            className="astronaut"
          >
            <div className="head" />
            <div className="arm arm-left" />
            <div className="arm arm-right" />
            <div className="body">
              <div className="panel" />
            </div>
            <div className="leg leg-left" />
            <div className="leg leg-right" />
            <div className="schoolbag" />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
